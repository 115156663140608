import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import FullWidthImage from "../components/FullWidthImage";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const FundraisingPageTemplate = ({
  image,
  title,
  subtitle,
  heading,
  description,
  intro,
  main,
  content,
  contentComponent
}) => {
  const heroImage = getImage(image) || image;
  const PageContent = contentComponent || Content;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            { (heading !== "" || description !== "") &&
            <div className="columns">
              <div className="column is-10 is-offset-1">
                { heading !== "" &&
                <h3 className="has-text-weight-semibold is-size-2">
                  {heading}
                </h3>
                }
                { description !== "" &&
                  <p>{description}</p>
                }
              </div>
            </div>
            }
            <div className="columns">
              <div className="column is-10 is-offset-1">
                { intro.blurbs.length > 0 &&
                <Features gridItems={intro.blurbs} /> 
                }
                { (main.heading !== "" || main.description !== "") &&
                <div className="columns">
                  <div className="column is-12">
                    { main.heading !== "" &&
                    <h3 className="has-text-weight-semibold is-size-3">
                      {main.heading}
                    </h3>
                    }
                    { main.description !== "" &&
                      <p>{main.description}</p>
                    }
                  </div>
                </div>
                }
                <div className="columns">
                  <div className="column is-12">
                    <PageContent className="content" content={content} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

FundraisingPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
  }),
  content: PropTypes.string,
  contentComponent: PropTypes.func
 };

const FundraisingPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FundraisingPageTemplate
        contentComponent={HTMLContent}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        heading={post.frontmatter.heading}
        description={post.frontmatter.description}
        intro={post.frontmatter.intro}
        main={post.frontmatter.main}
        content={post.html}
      />
    </Layout>
  );
};

FundraisingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default FundraisingPage;

export const FundraisingPageQuery = graphql`
  query FundraisingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
            website
          }
        }
        main {
          heading
          description
        }
      }
    }
  }
`;
